import Loading from '@components/Loading';
import { AuthContext } from '@context/auth/AuthContext';
import { SnackbarProvider } from 'notistack';
import { useContext, useEffect } from 'react';
import './App.css';
import { SnackbarUtilsConfigurator } from './common/helpers/snackbarUtils';
import './assets/i18n/config';
import SchedulerRoutes from './areas/Scheduler/routes/SchedulerRoutes';


function App() {
    const url = window.location.href;

    useEffect(() => {
        const lang = sessionStorage.getItem('lang');
        !lang && sessionStorage.setItem('lang', 'es');
    }, []);

    if (sessionStorage.getItem('area') !== "scheduler") { sessionStorage.setItem('changeArea', 'yes') }
    sessionStorage.setItem('area', 'scheduler');

    const { status } = useContext(AuthContext);
    if (status === 'checking') return <Loading />;

    const areaName = sessionStorage.getItem('area');

    return (
        <SnackbarProvider>
            <SnackbarUtilsConfigurator />
            <div>
                {areaName === 'scheduler' ? <SchedulerRoutes /> : null}
            </div>
        </SnackbarProvider>
    );
}

export default App;